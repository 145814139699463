import { useContext } from "react"
import { PokeContext } from "../Context"
import { PokemonCard } from "../components/PokemonCard"

export default function PokemonListScreen() {
  const { listaPokemon, setListaPokemon } = useContext(PokeContext)  // uso mi contexto

  return (
    <section className="container">
      <div className="row">
        {
          listaPokemon.map(
            (pokemon) =>
            (
              <PokemonCard nombre={pokemon.name} urlSprite={pokemon.sprite} idPokemon={pokemon.id} key={pokemon.id} />
            )
          )
        }
      </div>
    </section>
  )
}
