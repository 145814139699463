import { useEffect, useState, createContext } from 'react'

export const PokeContext = createContext()  // mi contexto pokemon, pokeContext
export const PokemonProvider = ({ children }) => {  // PokemonProvider llenará listaPokemon y setListaPokemon

    const [listaPokemon, setListaPokemon] = useState([])

    useEffect(() => {
        if (listaPokemon.length === 0) {  // vemos si el array de pokemon está vacío, si está vacío, lo llenamos con la api (se entiende mejor que montar/desmontar)
            fetch("https://pokeapi.co/api/v2/pokemon?limit=150")  // link con pokemones en api, 150 primeros
                .then(response => response.json())  // respuesta en json
                .then(({ results }) => {
                    setListaPokemon(results.map((pokemon, index) => (
                        {
                            ...pokemon,  // clono objeto pokemon
                            id: parseInt(index) + 1, // el id del pokemon es el index + 1
                            sprite: `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${parseInt(index) + 1}.png`,  // url de imagenes armado
                            favourite: false  // aprovechamos para agregar el atributo favourite
                        })))
                })
        }
    }, [listaPokemon, setListaPokemon])

    return (
        <PokeContext.Provider value={{ listaPokemon, setListaPokemon }}>
            {children}
        </PokeContext.Provider>
    )
}