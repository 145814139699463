import { useContext, useEffect } from "react"
import { PokeContext } from "../Context"
import { PokemonCard } from "../components/PokemonCard"
import { Row, Container } from "react-bootstrap"

export default function FavoriteListScreen() {
  const { listaPokemon, setListaPokemon } = useContext(PokeContext)
  const listaFavoritos = listaPokemon.filter(pokemon => pokemon.favourite)  // filtro los que tengan atributo favourite = true

  return (
    <Container>
      <Row>
        {
          listaFavoritos.map(
            (pokemon) =>
            (
              <PokemonCard nombre={pokemon.name} urlSprite={pokemon.sprite} idPokemon={pokemon.id} key={pokemon.id} />
            )
          )
        }
      </Row>
    </Container>
  )
}