import Navigation from "./components/Navigation";
import FavoriteListScreen from "./screens/FavoriteListScreen";
import PokemonListScreen from "./screens/PokemonListScreen";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { PokemonProvider } from "./Context";

function App() {

  return (
    <PokemonProvider>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<PokemonListScreen />} />
          <Route path="/favoritos" element={<FavoriteListScreen />} />
        </Routes>
      </BrowserRouter>
    </PokemonProvider>
  );
}

export default App;
