import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'

export default function Navigation() {
  return (
    <Navbar sticky="top" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">Pokemons</Navbar.Brand>
        <Nav className="mr-auto">
          <LinkContainer to="/">
            <Nav.Link>Listado</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/favoritos">
            <Nav.Link>Favoritos</Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>

  )
}