import { useContext, useState } from "react"
import { PokeContext } from "../Context"
import { Card, Col, Button } from "react-bootstrap"


export function PokemonCard({ nombre, urlSprite, idPokemon }) {

    const { listaPokemon, setListaPokemon } = useContext(PokeContext)

    const toggleFavourite = (id) => {  // función para cambiar el atributo favourite
        const newPokemonList = listaPokemon.map(pokemon => {
            if (pokemon.id === id) {
                return {
                    ...pokemon,
                    favourite: !pokemon.favourite  // nego el valor de favourite
                }
            }
            return pokemon
        })

        setListaPokemon(newPokemonList)  // seteo el nuevo array de pokemon modificado

    }

    return (
        <>
            <Col style={{ margin: 10 + 'px' }}>
                <Card style={{ width: 12 + 'rem' }}>
                    <Button variant="danger" onClick={() => toggleFavourite(idPokemon)} style={{ position: 'absolute', right: 0, top: 0, margin: 5 + 'px' }}>{listaPokemon[idPokemon - 1].favourite ? '★' : '♥'}</Button>
                    <Card.Img variant="top" src={urlSprite} alt={nombre} />
                    <Card.Body>
                        <Card.Title>{nombre}</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}